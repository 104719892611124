import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/base/layout"
import Hero from "../components/section/hero"
import Article from "../components/section/article";

export default function Home() {
  const data = useStaticQuery(query);
  return (
    <Layout seo={data.strapiHome.seo} slug={data.strapiHome.slug_de} lang="de" currentId="1">
      <Hero title={data.strapiHome.hero.title_de} />
      <Article list={data.strapiHome.article} lang="de" />
    </Layout>
  );
}

const query = graphql`
  query {
    strapiHome {
      slug_de
      seo {
        meta_title_de
        meta_description_de
      }
      hero {
        title_de
      }
      article {
        type
        subtext_de
        text_de
        button {
          label
          url
        }
      }
    }
  }
`;